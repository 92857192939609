<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Virtual User" class="mb-3" />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-3">
        <b-row>
          <b-col cols="4">
            <h2 class="mb-2">Virtual User Management</h2>
          </b-col>
          <b-col cols="8">
            <div class="d-flex justify-content-end import mb-1">
              <b-button variant="primary" class="btn-add" size="sm" @click="showForm">
                <feather-icon icon="PlusIcon" size="20" />
              </b-button>
              <label for="upload" class="btn btn-import btn-primary">
                <span>IMPORT</span>
                <input
                  type="file"
                  id="upload"
                  ref="files"
                  style="display: none"
                  @change="handleFilesUpload"
                >
              </label>
              <p class="rows-uploaded">{{ excelData.length + ' rows uploaded' }}</p>
              <b-button variant="success" class="btn-add" size="sm" @click="updateLoadData">SAVE</b-button>
            </div>
          </b-col>
        </b-row>
        <virtual-user-form-create @getList="getList" ref="virtualUserFormCreate" />
        <b-card>
          <virtual-user-table
            @getList="getList"
            :virtual-users="virtualUsers"
            :is-loading="isLoading"
          />
        </b-card>
      </section>
    </b-media>
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>

<script>
import XLSX from 'xlsx'
import service from "../service";
import VirtualUserTable from "./_components/VirtualUserTable.vue";
import virtualUserFormCreate from './_components/VirtualUserFormCreate.vue'
import { RANKS } from './../constants'

const VALID_FILE_EXTENSIONS = 'xlsx'

export default {
  components: {
    VirtualUserTable,
    virtualUserFormCreate
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 50,
      virtualUsers: [],
      excelData: [],
      isLoading: false,
      query: null,
    };
  },
  watch: {
    current_page() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(query) {
      this.isLoading = true;
      let response = await service.getList({
        query,
        page: this.current_page,
        limit: this.items_perpage,
      });
      this.isLoading = false;
      if (response.data.type === "DATA") {
        this.virtualUsers = response.data.data.list;
        this.total_items = response.data.data.total;
      }
    },

    handleFilesUpload(e) {
      var input = e.target;
      var reader = new FileReader();
      if (!input.files[0] || !this.checkValidFileExtension(input.files[0].name)) {
        return this.$store.dispatch("pushErrorNotify", {
          text: "File upload is invalid!",
        })
      }
      reader.onload = () => {
        var fileData = reader.result;
        var wb = XLSX.read(fileData, { type: 'binary' });
        wb.SheetNames.forEach((sheetName) => {
          var rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
          this.excelData = rowObj
        })
      };
      reader.readAsBinaryString(input.files[0]);
    },

    checkValidFileExtension(fileName) {
      if (!fileName) { return false }
      return fileName.substr(fileName.length - VALID_FILE_EXTENSIONS.length, VALID_FILE_EXTENSIONS.length).toLowerCase() === VALID_FILE_EXTENSIONS
    },

    showForm() {
      this.$refs.virtualUserFormCreate.showForm();
    },

    async updateLoadData() {
      this.isLoading = true
      const dataUpload = this.excelData.map(usr => {
        let user = Object.values(usr)
        return {
          user_name: user[0],
          country: user[1],
          language: user[2],
          rank: RANKS[user[3].toUpperCase()]
        }
      })
      if (!dataUpload.length) {
        this.isLoading = false
        return this.$store.dispatch("pushErrorNotify", {
          text: "users data is empty!",
        })
      }

      let res = await service.createMany(dataUpload);
      this.isLoading = false
      if (res) {
        this.$store.dispatch("pushSuccessNotify", { text: "Successfully!" });
        this.$emit("getList");
        this.excelData = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-import {
  margin-left: 5px;
  display: table;
  margin-bottom: 0;
  span {
    display: table-cell;
  }
}

.rows-uploaded {
  margin: auto 5px;
  padding: 10px 5px;
  border: solid 1px;
  border-radius: 10px;
  font-weight: 800;
}
</style>

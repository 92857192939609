<template>
  <div>
    <!-- <user-search-form @getList="getList" /> -->
    <div v-if="isLoading" class="d-flex justify-content-center mb-1">
      <b-spinner class="mr-1" variant="primary" />
    </div>
    <b-table v-else :items="virtualUsers" :fields="fields" responsive="sm">
      <template #cell(user_name)="data">
        <b-form-input v-if="virtualUserId == data.item.id" v-model="data.item.user_name" placeholder="User name" />
        <p v-else>{{ data.item.user_name }}</p>
      </template>

      <template #cell(country)="data">
        <b-form-input v-if="virtualUserId == data.item.id" v-model="data.item.country" placeholder="Country" />
        <p v-else>{{ data.item.country }}</p>
      </template>

      <template #cell(language)="data">
        <b-form-select v-if="virtualUserId == data.item.id" v-model="data.item.language" :options="LANGUAGE_OPTIONS" />
        <p v-else>{{ getLanguage(data.item.language) }}</p>
      </template>

      <template #cell(rank)="data">
        <b-form-select v-if="virtualUserId == data.item.id" v-model="data.item.rank" :options="RANK_OPTIONS" />
        <p v-else>{{ getRank(data.item.rank) }}</p>
      </template>

      <template #cell(action)="data">
        <div class="d-flex flex-end">
          <b-button
            @click="editOrUpdate(data.item.id)"
            size="sm"
            style="margin-right: 5px"
            variant="primary"
          >{{ virtualUserId == data.item.id ? "Save" : "Edit" }}</b-button>
          <b-button
            size="sm"
            variant="danger"
            @click="confirmDelete(data.item.id)"
          >{{ virtualUserId == data.item.id ? "Cancel" : "Delete" }}</b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import UserSearchForm from "./UserSearchForm.vue";
import service from './../../service'
import { RANK_OPTIONS, LANGUAGE_OPTIONS } from './../../constants.js'

export default {
  components: {
    UserSearchForm,
  },
  props: {
    virtualUsers: { type: Array, required: true },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      fields: ["user_name", "country", "language", "rank", { key: "action", label: "" }],
      language: null,
      virtualUserId: '',
      LANGUAGE_OPTIONS,
      RANK_OPTIONS,
    };
  },
  methods: {
    getList(dataForm = {}) {
      this.$emit("getList", dataForm);
    },

    async editOrUpdate(id) {
      if (!(this.virtualUserId + '') || this.virtualUserId != id) {
        this.virtualUserId = id;
      } else {
        const response = await this.updateConfigs(id);
        if (response.data.type == "SUCCESS") {
          this.virtualUserId = null;
          this.$emit("getList");
        }
      }
    },

    async updateConfigs(id) {
      const dataUpdate = JSON.stringify(this.virtualUsers.find(usr => usr.id === id))

      return await service.update(JSON.stringify(dataUpdate));
    },

    async cancelOrDelete(id) {
      if (this.virtualUserId == id) {
        this.virtualUserId = '';
      } else {
        await service.delete({
          id
        })
        this.$emit("getList");
      }
    },

    getLanguage(language) {
      const lang = LANGUAGE_OPTIONS.find(lg => lg.value === language)
      return lang ? lang.text : ''
    },

    getRank(rank) {
      const rk = RANK_OPTIONS.find(rk => rk.value === rank)
      return rk ? rk.text : ''
    },

    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.cancelOrDelete(id);
          }
        });
    },
  },
};
</script>

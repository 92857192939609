<template>
  <div>
    <b-card v-if="isDisplay">
      <b-row>
        <b-col md="3">
          <b-form-group label="User name" label-for="user_name">
            <b-form-input
              v-model="dataForm.user_name"
              id="user_name"
              type="text"
              placeholder="User name"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Country" label-for="country">
            <b-form-input v-model="dataForm.country" placeholder="Country" />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Language" label-for="language">
            <b-form-select v-model="dataForm.language" :options="LANGUAGE_OPTIONS" />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Rank" label-for="rank">
            <b-form-select v-model="dataForm.rank" :options="RANK_OPTIONS" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-button @click="create" class="mt-0 mt-md-2 mr-1" variant="primary">
            Save
          </b-button>
          <b-button
            @click="showForm"
            class="mt-0 mt-md-2"
            variant="secondary"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";
import service from "../../service";
import { RANK_OPTIONS, LANGUAGE_OPTIONS } from './../../constants.js'

export default {
  mixins: [heightTransition],

  data() {
    return {
      isDisplay: false,
      dataForm: {},
      LANGUAGE_OPTIONS,
      RANK_OPTIONS
    };
  },

  methods: {
    showForm() {
      this.isDisplay = !this.isDisplay;
    },

    async create() {
      let res = await service.create(this.dataForm);
      if (res) {
        this.$store.dispatch("pushSuccessNotify", { text: "Successfully!" });
        this.showForm();
        this.$emit("getList");
        this.dataForm = {};
      }
    },
  },
};
</script>

<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group label="User name" label-for="user_name">
          <b-form-input
            v-model="dataForm.user_name"
            id="user_name"
            type="text"
            placeholder="User name"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Email" label-for="email">
          <b-form-input
            v-model="dataForm.email"
            id="email"
            type="text"
            placeholder="Email"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-button @click="search" class="mt-0 mt-md-2 mr-1" variant="primary">
          Search
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataForm: {},
    };
  },
  methods: {
    search() {
      this.$emit("getList", this.dataForm);
    },
  },
};
</script>
